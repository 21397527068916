@font-face {
  font-family: "TT Hoves";
  src: url("/public/fonts/TTHoves/TTHoves-Regular.ttf") format("truetype");
  font-weight: 400; /* Normal weight */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "TT Hoves";
  src: url("/public/fonts/TTHoves/TTHoves-Medium.ttf") format("truetype");
  font-weight: 500; /* Medium weight */
  font-style: normal;
  font-display: swap;
}
